import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import _ from 'lodash'

import '../styles/prism.css'
import mq from '../styles/mediaQueries'

import Layout from '../components/Layout'
import Content from '../components/Content'
import Seo from '../components/seo'
import DarkBlogPost from './dark-blog-post'

import formatPostTitle, { formatTitle } from '../utils/formatPostTitle'
import { Subheading1, Headline6, Body2 } from '../components/Typography'

import TwitterSVG from '../assets/icons/twitter.svg'
import LinkedInSVG from '../assets/icons/linkedin.svg'
import FacebookSVG from '../assets/icons/facebook.svg'
import LinkSVG from '../assets/icons/link.svg'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'

const Twitter = props => {
  const StyledTwitter = styled(TwitterSVG)`
    pointer-events: auto;
    cursor: pointer;
    margin-right: 16px;
    height: 24px;
    width: 24px;

    path {
      fill: var(--terminal-0);
    }
  `

  return (
    <a href="https://github.com/SuperGoodSoft">
      <StyledTwitter {...props} />
    </a>
  )
}

const LinkedIn = props => {
  const StyledLinkedIn = styled(LinkedInSVG)`
    pointer-events: auto;
    cursor: pointer;
    margin-right: 16px;
    height: 24px;
    width: 24px;

    path {
      fill: var(--terminal-0);
    }
  `

  return (
    <a href="https://ca.linkedin.com/company/supergoodsoft">
      <StyledLinkedIn {...props} />
    </a>
  )
}

const Facebook = props => {
  const StyledFacebook = styled(FacebookSVG)`
    pointer-events: auto;
    cursor: pointer;
    margin-right: 16px;
    height: 24px;
    width: 24px;

    path {
      fill: var(--terminal-0);
    }
  `

  return (
    <span>
      <StyledFacebook {...props} />
    </span>
  )
}

const LinkIcon = props => {
  const StyledLinkIcon = styled(LinkSVG)`
    pointer-events: auto;
    cursor: pointer;
    margin-right: 16px;
    height: 24px;
    width: 24px;

    path {
      stroke: var(--terminal-0);
    }
  `

  return (
    <div>
      <StyledLinkIcon {...props} />
    </div>
  )
}

const Wrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '4', '5', '5'],
    gridColumnEnd: ['5', '9', '13', '13', '13'],
  })};
`

const LeftColumnWrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '1', '1', '1'],
    gridColumnEnd: ['5', '9', '4', '5', '5'],
    display: ['none', 'none', 'block', 'block', 'block'],
  })};
`

const SubHeading = styled.div`
  ${Subheading1}
  color: var(--terminal-100);
  border-left: 4px solid var(--terminal-300);
  ${mq({
    gridColumnStart: ['1', '1', '1', '1', '1'],
    gridColumnEnd: ['5', '8', '11', '9', '9'],
    paddingLeft: ['24px', '24px', '36px', '36px', '36px'],
    margin: ['60px 0', '60px 0', '80px 0 63px 0', '80px 0', '80px 0'],
  })};
`

const CaptionHeader = styled.div`
  ${Headline6};
  color: var(--terminal-0);
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 20px;
`

const CaptionBody = styled.div`
  ${Body2};
  color: var(--terminal-100);
`

const CaptionLink = styled(Link)`
  text-decoration: none;
  color: var(--screen-300);
`

const SocialListItem = styled.li`
  list-style: none;
  display: inline-block;
`

const SocialWrapper = styled.ul`
  display: block;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 24px 0;
`

const OrangeLink = styled(Link)`
  ${Body2}
  font-weight: bold;
  text-decoration: none;
  color: var(--firewall-500);
`

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const postCover = post.frontmatter.socialCover.childImageSharp.resize.src
    const postTitle = formatTitle(formatPostTitle(post.frontmatter))
    const postAuthor = post.frontmatter.author
    const postCaption = `${postAuthor} on ${post.frontmatter.date}`
    const pageTitle = 'Blog'
    const pageLink = this.props.location.href

    const postDate = new Date(post.frontmatter.date)
    const postYear = postDate.getFullYear()

    function copyToClipboard() {
      navigator.clipboard.writeText(pageLink)
      alert('Link Copied')
    }

    return (
      <DarkBlogPost
        location={this.props.location}
        pagetitle={pageTitle}
        image={post.frontmatter.cover.children[0].gatsbyImageData}
        title={postTitle}
        caption={postCaption}
        copyrightYear={postYear}
      >
        <Seo
          title={formatPostTitle(post.frontmatter)}
          description={post.frontmatter.description || post.excerpt}
          author={post.frontmatter.author}
          keywords={[
            `Ruby on Rails`,
            `Rails`,
            `React`,
            `Spree`,
            `Solidus`,
            `eCommerce`,
          ]}
          image={postCover}
        />
        <Layout>
          <SubHeading>{post.frontmatter.description}</SubHeading>
          <LeftColumnWrapper>
            <CaptionHeader>{postTitle}</CaptionHeader>
            <CaptionBody>
              {postAuthor} on {post.frontmatter.date}
              <br />
              Posted in{' '}
              <CaptionLink
                to={`/blog/${_.kebabCase(post.frontmatter.category)}/`}
              >
                {_.capitalize(post.frontmatter.category)}
              </CaptionLink>
            </CaptionBody>
            <SocialWrapper>
              <SocialListItem>
                <TwitterShareButton url={pageLink}>
                  <Twitter />
                </TwitterShareButton>
              </SocialListItem>
              <SocialListItem>
                <LinkedinShareButton url={pageLink}>
                  <LinkedIn />
                </LinkedinShareButton>
              </SocialListItem>
              <SocialListItem>
                <FacebookShareButton url={pageLink}>
                  <Facebook />
                </FacebookShareButton>
              </SocialListItem>
              <SocialListItem>
                <LinkIcon onClick={copyToClipboard} />
              </SocialListItem>
            </SocialWrapper>
            <OrangeLink to="/blog">← Back to the blog</OrangeLink>
          </LeftColumnWrapper>
          <Wrapper>
            <Content theme={'night'} smallerHeaders={true}>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </Content>
          </Wrapper>
        </Layout>
      </DarkBlogPost>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        author
        series
        category
        description
        date(formatString: "MMMM DD, YYYY")
        description
        cover {
          children {
            ... on ImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        socialCover: cover {
          childImageSharp {
            resize(width: 1200) {
              src
            }
          }
        }
      }
    }
  }
`
