import React from 'react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import mq from '../styles/mediaQueries'
import { BgImage as BackgroundImage } from 'gbimage-bridge'

import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import NavOverlay from '../components/NavOverlay'
import Layout from '../components/Layout'
import SubscribeForm from '../components/SubscribeForm'
import { Body1, Headline2 } from '../components/Typography'

const Wrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '2', '3', '3'],
    gridColumnEnd: ['5', '9', '12', '11', '11'],
  })};
  ${props => (props.isOverlayOpen ? 'hidden' : 'visible')};
`

const BackgroundWrapper = styled.div`
  background: var(--terminal-500);
  height: auto;
`

const SubscribeFormWrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '4', '5', '5'],
    gridColumnEnd: ['5', '9', '13', '13', '13'],
  })};
`

const Header = styled(BackgroundImage)`
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${mq({
    padding: ['24px 20px', '36px', '60px 132px', '80px 132px', '80px 132px'],
  })};
`

const BlogTitle = styled.div`
  ${Headline2}
  color: var(--terminal-0);
`

const BlogCaption = styled.div`
  ${Body1}
  color: var(--terminal-50);
  margin-top: 12px;
`

function DarkBlogPost(props) {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  const [pixels, setPixels] = useState(0)
  const [formRef, formInView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  })

  const imageStack = [
    `linear-gradient(0deg, rgba(46, 43, 38, 0.4), rgba(46, 43, 38, 0.4))`,
    props.image,
  ]

  return (
    <React.Fragment>
      <NavBar
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
        pixels={pixels}
        setPixels={setPixels}
        theme={'night'}
        title={props.pagetitle}
      />
      <NavOverlay
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
      />
      <Header image={imageStack}>
        <BlogTitle>{props.title}</BlogTitle>
        <BlogCaption>{props.caption}</BlogCaption>
      </Header>
      <BackgroundWrapper>
        {props.children}
        <Layout>
          <SubscribeFormWrapper ref={formRef}>
            {formInView ? <SubscribeForm light={true} /> : null}
          </SubscribeFormWrapper>
          <Wrapper>
            <Footer color={'dark'} copyrightYear={props.copyrightYear} />
          </Wrapper>
        </Layout>
      </BackgroundWrapper>
    </React.Fragment>
  )
}

export default DarkBlogPost
